import AbstractPlayerController from "./abstract_player_controller";
import Hls, { Events } from "hls.js";

export default class extends AbstractPlayerController {
  static targets = ["videoTag"];
  static values = { manifestUrl: String, hlsId: String };

  connect() {
    if (Hls.isSupported() && this.hasManifestUrlValue) {
      this.hls = new Hls();
      this.hls.loadSource(this.manifestUrlValue);

      this.hls.on(Events.MANIFEST_LOADED, () =>
        this.hls.attachMedia(this.videoTagTarget),
      );
    }
  }
}
